import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import './App.css';
import { useQuery, gql } from '@apollo/client'

// page and layout imports
import Loading from './components/Loading'
import Header from './components/Header'
import Footer from './components/Footer'
import Homepage from './pages/Homepage'
import About from './pages/About'
import Services from './pages/Services'
import Technologies from './pages/Technologies';
import Blog from './pages/Blog'
import Post from './pages/Post'
import Contact from './pages/Contact'

// apollo client 
const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache()
})

function App() {
  // const { loading, error, data } = useQuery(TITLE)
  //   if (loading) return <Loading />
  //   if (error) return <p>Error</p>
  //   const title = data.homepage.data.attributes.Title
  const title = "Bayesics"

  return (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <ApolloProvider client={client}>
        <div className="App">
          <Header />
          <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/technologies" element={<Technologies />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<Post />} />
              <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </div>
      </ApolloProvider>
    </Router>
    
  );
}

export default App;
