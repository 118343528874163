import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Pagination } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import useGetTitle from '../hooks/useGetTitle'

const { REACT_APP_API_URL } = process.env

const BLOG_PAGE = gql`
    query {
        blog {
            data {
                attributes {
                    Title,
                    Posts_per_page
                }
            }
        }
        blogPosts {
            data {
                id,
                attributes {
                    Title,
                    Subtitle,
                    Content,
                    Image {
                        data {
                            attributes {
                                url
                            }
                        }
                    },
                    Date
                }
            }
        }
    }
`

export default function Blog() {
    const title = useGetTitle()
    const [page, setPage] = useState(1)
    const handleChange = (event, value) => {
        setPage(value);
    }
    const { loading, error, data } = useQuery(BLOG_PAGE)
  
    if (loading) return <p>Loading</p>
    if (error) return <p>Error</p>
  
    console.log(data)

    const numPosts = data.blogPosts.data.length;
    const numPostPerPage = data.blog.data.attributes.Posts_per_page;
    const start = (page-1) * numPostPerPage;
    const end = start + numPostPerPage;

    return(
        <React.Fragment>
            <Helmet>
                <title>{`Blog | ${title}`}</title>
            </Helmet>
            <div className="px-lg-5 py-5 banner">
                <h2 className="py-md-5 display-5 fw-bold text-center">{data.blog.data.attributes.Title}</h2>
            </div>
            <div className="px-3 px-lg-5">
                <div className="px-lg-5 py-lg-2 my-5">
                    {(data.blogPosts.data.length > 0) ? 
                    data.blogPosts.data.slice(start, end).map(post => (
                        <div key={post.id} className="row p-3 px-lg-5 gx-5 my-5">
                            
                            <div className="col-12 col-md-8 order-md-3">
                                <h2 className="lh-1 fw-bold">{post.attributes.Title}</h2>
                                {post.attributes.Subtitle && <h6 className="lh-1">{post.attributes.Subtitle}</h6>}
                                <p className="text-muted"><small>Published on {post.attributes.Date}</small></p>
                                <p>{(post.attributes.Content.length >= 400) ? post.attributes.Content.substring(0, 400) + '...' : post.attributes.Content}</p>
                                <Link to={`/blog/${post.id}`}>Read more</Link>
                                
                            </div>
                            {post.attributes.Image.data && 
                                <div className="col-12 col-md-4 order-md-2">
                                    <img src={REACT_APP_API_URL + post.attributes.Image.data.attributes.url} width="100%"/>
                                </div>
                            }
                        </div>
                    )) :
                    // no posts
                    <div className="text-center">
                        <h1 className="display1">Oops!</h1> 
                        <p>There are no posts at the moment. Please check back later. In the meantime, please contact us for more information.</p>
                        <a className="btn btn-primary mb-3" href="/contact" role="button">Get in touch</a>
                    </div>}
                    {(data.blogPosts.data.length > 0) ? <div className="row">
                        <div className="col d-flex justify-content-center">
                            <Pagination 
                                count={Math.ceil(numPosts / numPostPerPage)}
                                onChange={handleChange} />
                        </div>
                    </div> : <div />}
                    
                </div>
            </div>
        
        </React.Fragment>
        
    )
}
