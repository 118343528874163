import React from 'react'
import { CircularProgress } from '@mui/material'

export default function Loading() {
  return (
    <div className="loading d-flex justify-content-center align-items-center">
      <div>
      <CircularProgress color="inherit"/>
      </div>
    </div>
  )
}
