import { useQuery, gql } from '@apollo/client'


const TITLE = gql`
    query {
        homepage {
            data {
                attributes {
                    Title
                }
            }
        }
    }`

export default function useGetTitle() {
    const { loading, error, data } = useQuery(TITLE)
    if (error) {
        console.log('failed to get title')
        return 'Bayesics' // default title
    } else if (!loading) {
        return data.homepage.data.attributes.Title
    }
}