import React from 'react'
import { Helmet } from 'react-helmet'
import { useQuery, gql } from '@apollo/client'

import Jumbotron from '../components/Jumbotron'
import Values from '../components/Values'
import ProductSummary from '../components/ProductSummary'
import GridGallery from '../components/GridGallery'
import BlogCarousel from '../components/BlogCarousel'
import ContactComponent from '../components/ContactComponent'
import Loading from '../components/Loading'


const delay = ms => new Promise(res => setTimeout(res, ms));

// query for getting Homepage fields from CMS
const HOMEPAGE = gql`
    query {
        homepage {
            data {
                attributes {
                    Title,
                    Hero {
                        Title,
                        Description,
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Link {
                            Name,
                            URL
                        }
                    }
                    Values {
                        Title, 
                        Description,
                        Columns {
                            Title,
                            Description
                        }
                    }
                    Solutions {
                        Title,
                        Description,
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Link {
                            Name,
                            URL
                        }
                    }
                    Stare {
                        Header {
                            Title,
                            Description
                        }
                        Sections {
                            Title,
                            Description,
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            id
                        }
                    }
                }
            }
        }
        blogPosts(sort:"Date:desc") {
            data {
              id,
              attributes {
                Title,
                Subtitle,
                Content,
                Image {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                Date
              }
            }
        }
    }`


/* Responsible for rendering the main page (Homepage single type in CMS). Has fields for:
   - Title: This is the title of the website and used in the <head> <title> tag on all pages.
   - Hero (Hero component in CMS): This is the main landing area for the website. Rendered as
     a Jumbotron component. Contains a Title, Description, Image (url), and Link component 
     consisting of a Name and URL, which are passed to the Jumbotron component as props.
*/
export default function Homepage() {
    // getting Homepage fields from CMS
    const { loading, error, data } = useQuery(HOMEPAGE)
    if (loading) {
        return (
            <Loading />
        )
    }
    if (error) return <p>Error</p>

    // alias homepage data for ease of reference in return() 
    const homepage = data.homepage.data.attributes 
    const hero = homepage.Hero
    const values = homepage.Values
    const productSummary = homepage.Stare

    const numPosts = 12 
    const posts = data.blogPosts.data.slice(0, numPosts)

    console.log(productSummary)

    return (
        <React.Fragment>
            {/* Changes the meta <title> tags in the <head> of document*/}
            <Helmet>
                <title>{homepage.Title}</title>
            </Helmet>

            {/* Hero component in CMS, Jumbotron styled with main-hero class*/}
            <Jumbotron title={hero.Title} 
                description={hero.Description} 
                imgUrl={hero.Image.data.attributes.url}
                linkName={hero.Link.Name}
                linkUrl={hero.Link.URL} 
                className="main-hero"/>
        
            
            <Values title={values.Title} description={values.Description} values={values.Columns} />

            <ProductSummary header={productSummary.Header} sections={productSummary.Sections}/>

            <GridGallery items={homepage.Solutions} />

            <BlogCarousel posts={posts}/>

            <ContactComponent />

        </React.Fragment>
        
    )
}
