import React from 'react'
import { Navbar, Nav, NavItem } from 'reactstrap'
import { Skeleton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'


// query for getting footer fields from CMS
const FOOTER = gql`
    query {
        footer {
            data {
                attributes {
                    Title
                    Description
                    Sections {
                        Title,
                        Links {
                        Name,
                        URL
                        }
                    }
                }
            }
        }
    }`


/* Component responsible for the layout of the footer. Corresponds to the Footer 
   single type in CMS. Has fields for:
   - Title and Description, which appear on the left side of the footer.
   - Link Sections (Footer Section component in the CMS) that display a section title 
     and a list of links (NavLink component in the CMS) that occupy the right side of the footer.
     Can accomodate up to 3 link sections with the current grid layout.
*/
export default function Footer() {
    // getting Footer fields from CMS
    const { loading, error, data } = useQuery(FOOTER)
    if (loading) return <React.Fragment />
    if (error) return <p>Error</p>

    // alias footer data for ease of reference in return()
    const footer = data.footer.data.attributes

    return (
        <footer>
            <div className="mx-3 mx-md-4 py-5">
                <div className="row row-cols-2 row-cols-md-5">
                    <div className="col px-md-4">
                        <h5 className="fw-bold">{footer.Title}</h5>
                        <p className="text-muted">{footer.Description}</p>
                    </div>
                    
                    {/* separating col between the Title/description section and link sections */}
                    <div className="col" />

                    {/* Maps through the Footer Section components and displays each as a col */}
                    {footer.Sections.map(section => (
                        <div className="col px-4 my-3 my-md-0">
                            <h6>{section.Title}</h6>
                            <Navbar container={false}>
                               
                                <Nav navbar light>
                                    {/* Maps through the NavLink components and displays links as
                                        list vertically */}
                                    {section.Links.map(link => (
                                        <NavItem>
                                            <NavLink className="nav-link lh-1" to={link.URL}>{link.Name}</NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </Navbar>
                            
                            
                        </div>
                    ))}
                </div>

            </div>
        </footer>
    )
}
