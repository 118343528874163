import React from 'react'
import { Helmet } from 'react-helmet'
import useGetTitle from '../hooks/useGetTitle'

import UnderConstruction from '../components/UnderConstruction'

export default function Services() {
  const title = useGetTitle()

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Services | ${title}`}</title>
      </Helmet>
      
      <UnderConstruction />
      
    </React.Fragment>
    
  )
}
