import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { Grid, Card, CardHeader, CardContent } from '@mui/material/'


export default function GridGallery({ items }) {
  const numItemsPerRow = 4
  function GalleryCard({ item }) {
    return (
      <div className="ratio ratio-4x3 text-center gallery-card">
        <div className="d-flex p-4 align-items-center">
          <div className="position-absolute gallery-card-title">
            <h3>{item.Title}</h3>
          </div>
          <div className="lh-1 gallery-card-description">
            <small>{item.Description}</small>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="px-xl-5">
      <div className="my-5 mx-4 mx-sm-5 px-sm-3 px-md-5 py-5">
        <div className="row justify-content-center mb-3">
          <div className="col-12 col-lg-10 my-3 text-center">
            <h2>Some Title</h2>
            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <div className="col-12 my-3 px-lg-3 px-4 px-sm-3 px-xl-5">
            <Grid container spacing={{ xs: 3, sm: 1, md: 3 }}>
              {items.map(item => (
                <Grid item xs={12} sm={6} md={4} lg={12 / numItemsPerRow}>
                  <GalleryCard item={item} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        
      </div>
    </div>
    
  )
}


