import React from 'react'

export default function UnderConstruction() {
  return (
    <div className="loading d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h1 className="display-1">Coming Soon</h1>
        <p>This page is currently under construction. In the meantime, please contact us for more information.</p>
        <a className="btn btn-primary mb-3" href="/contact" role="button">Get in touch</a>
      </div>
    </div>
  )
}
