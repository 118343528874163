import React from 'react'


/* Component responsible for displaying the main hero at the top of pages. Contains the same
   fields as a normal hero component (taken as props from the page rendering it):
   - Title: displayed on left of screen
   - Description: displayed under title
   - ImageURL (relative to server address): displayed on right of screen for medium and larger
     screens, stacked above title and description for xs-small screens
   - LinkName and URL: displayed as button under description.
*/
export default function Jumbotron({ title, description, imgUrl, linkName, linkUrl, className}) {
    return (
        <div className={className}>
            <div className="row align-items-center flex-md-row-reverse">
                <div className="col-12 col-md-6">
                    <img src={process.env.REACT_APP_IMAGE_URL + imgUrl} width="100%" />
                </div>
                <div className="col-12 col-md-6 px-5">
                    <div className="my-5 my-md-0 ms-md-5">
                        <h1 className="display-3 fw-bold lh-1 my-3">{title}</h1>
                        <p className="lead lh-1 mb-4">{description}</p>
                        <a className="btn btn-primary mb-3" href={linkUrl} role="button">{linkName}</a>
                    </div>
                </div> 
            </div>
        </div>
        
    )
}
