import React, {useState} from 'react'
import { Button, TabContent, TabPane } from 'reactstrap'

export default function ProductSummary({ header, sections }) {
  const [activeTab, setActiveTab] = useState(1)

  console.log(activeTab)

  return (
    <div className="product px-xl-5">
      <div className="m-3 m-sm-5 p-3 p-lg-5">
        <div className="row px-xl-5 my-5">
          <div className="col-12 col-md-4 my-5">
            <h2>{header.Title}</h2>
            <p className="lead">{header.Description}</p>
            <Button color="primary">Learn More</Button>
          </div>
          <div className="col ms-md-5">
          
            <TabContent>
              {sections.map(section => 
                <TabPane className={section.id==activeTab ? "fade active show" : "fade"} tabId={section.id}>
                  <div className="row">
                    <div className="col">
                      <img src={process.env.REACT_APP_IMAGE_URL + section.Image.data.attributes.url} width="100%" />
                    </div>
                  </div>
                </TabPane>
              )}
            </TabContent>
          
            <div className="row md-px-5 mx-1 mt-4">
              <ul className="nav nav-underline">
                {sections.map(section =>
                    <li className="col nav-item">
                      <div className={"nav-link" + (section.id == activeTab ? " active" : "" )} onClick={() => {setActiveTab(section.id)}}>
                          <h3>{section.Title}</h3>
                          <p>{section.Description}</p>
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          </div>
     
        </div>
        
      </div>
    </div>
  )
}
