import React, { useState } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'

const { REACT_APP_API_URL } = process.env

export default function ContactForm() {
  const [formData, setFormData] = useState({
    message: '',
    name: '',
    email: ''
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch(REACT_APP_API_URL + '/api/messages', {
      method: 'POST',
      body: JSON.stringify({"data": formData}),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => {
        if (response.status !== 200) {
          alert("There was an error submitting the message. Please try again later.")
          response.json()
        } else {
          alert("Message successfully submitted.")
        }
      }).then((data) => {
        console.log(data)
      })

      // reset form
      setFormData({name: '', email: '', message: ''})
  }

  return (
    <Form id="contactForm" onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-12 col-xl-5">
                <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" id="name" name="name"
                           value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})} />
                </FormGroup>
                
            </div>
            <div className="col-12 col-xl">
                <FormGroup>
                    <Label htmlFor="email">Contact Email</Label>
                    <Input type="email" id="email" name="email"
                        value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})} />
                </FormGroup>
            </div>
            <FormGroup>
                <Label htmlFor="message">Message</Label>
                <Input type="textarea" id="message" name="message" rows={9}
                    value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})} />
            </FormGroup>
        </div>
        
        
        <Button type="submit" value="submit" color="primary">Submit</Button>
    </Form>
  )
}
