import React from 'react'

export default function Values({ title, description, values }) {
    console.log(values)
    return (
        <div className="values px-xl-5">
            <div className="m-3 mx-sm-5 p-3 p-lg-5">
    
                    <div className="row align-items-center px-xl-5 my-5">
                        <div className="col-12 col-md-5">
                            <h1>{title}</h1>
                        </div>
                        <div className="col-12 col-md-7 mt-1">
                            <p className="lead">{description}</p>
                        </div>
                    </div>
                    <div className="row gx-lg-5 px-xl-5 mb-3 value-card">
                        {values.map(value => (
                            <div className="col-12 col-md my-3">
                                <h5>{value.Title}</h5>
                                <p><small>{value.Description}</small></p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
  
    )
}
