import React from 'react'
import { Helmet } from 'react-helmet'
import useGetTitle from '../hooks/useGetTitle'

import UnderConstruction from '../components/UnderConstruction'

export default function Technologies() {
  const title = useGetTitle()

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Technologies | ${title}`}</title>
      </Helmet>
      

      <UnderConstruction />
      
    </React.Fragment>
  )
}