import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import useGetTitle from '../hooks/useGetTitle'

const { REACT_APP_IMAGE_URL } = process.env

const BLOG_POST = gql`
    query GetPost($id: ID!) {
        blogPost(id: $id) {
            data {
                id,
                attributes {
                    Title,
                    Subtitle, 
                    Date,
                    Content,
                    Image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
export default function Post() {
    const title = useGetTitle() 
    const { id } = useParams()
    const { loading, error, data } = useQuery(BLOG_POST, {
        variables: {id: id}
    })

    if (loading) return <p>Loading..</p>
    if (error) return <p>Error</p>

    const post = data.blogPost.data.attributes
    console.log(data)
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${post.Title} | ${title}`}</title>
            </Helmet>
            <div class="px-3 px-lg-5 py-5 mx-5">
                <div className="row">
                    <h1 className="fw-bold lh-1">{post.Title}</h1>
                    <h6 className="lh-1">{post.Subtitle}</h6>
                    <p className="text-muted"><small>Published on {post.Date}</small></p>
                </div>
                <div className="row gx-5 flex-row-reverse">
                    <div className="col-12 col-md-5 col-lg-4">
                        {post.Image.data && 
                            <img className="post-img" src={REACT_APP_IMAGE_URL + post.Image.data.attributes.url} width="100%"/>
                        }
                    </div>
                    <div className="col mt-4">
                        <p>{post.Content}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
        
    )
}
