import React from 'react'
import { Helmet } from 'react-helmet'
import useGetTitle from '../hooks/useGetTitle'
import { useQuery, gql } from '@apollo/client'

import ContactComponent from '../components/ContactComponent'
import ContactForm from '../components/ContactForm'
import Loading from '../components/Loading'

const CONTACT = gql`
  query {
    contact {
      data {
        attributes {
          Title,
          Description
        }
      }
    }
  }`

export default function Contact() {
  const title = useGetTitle()

  const { loading, error, data } = useQuery(CONTACT)
  if (loading) {
      return (
          <Loading />
      )
  }
  if (error) return <p>Error</p>

  const values = data.contact.data.attributes

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contact | ${title}`}</title>
      </Helmet>
      <div className="contact-page">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="row my-5 mx-3 p-5 mb-md-5">
              <div className="col">
                <h1 className="display-5 fw-bold">{values.Title}</h1>
                <p className="lead">{values.Description}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md contact-side">
            <div className="row p-5 my-5 ms-3 me-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
