import React from 'react'
import { Helmet } from 'react-helmet'
import useGetTitle from '../hooks/useGetTitle'

import UnderConstruction from '../components/UnderConstruction'


export default function About() {
  // get the title of the website from the homepage type
  const title = useGetTitle()

  return (
    <React.Fragment>
      <Helmet>
        <title>{`About | ${title}`}</title>
      </Helmet>
 
      <UnderConstruction />
 
    </React.Fragment>
  )
}
