import React, { useState } from 'react'
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

// query for getting header fields from CMS
const HEADER = gql`
query {
  header {
    data {
      attributes {
        Logo {
          data {
            attributes {
              url
            }
          }
        }
        Links {
          Name,
          URL
        }
      }
    }
  }
}`


/* Component responsible for the layout of the header. Corresponds to the Header 
   single type in CMS. Has fields for:
   - Logo, which contains a url (relative to the server address) to the image. 
     Appears on the left side of the header.
   - Links (NavLink component in the CMS), consisting of a list of links that display
     horizontally on the right side of the header. 
*/
export default function Header() {
    // toggle function for navbar toggler
    const [toggle, setToggle] = useState(false)
    const toggleNav = () => {
        setToggle(!toggle)
    }

    // getting Header fields from CMS
    const { loading, error, data } = useQuery(HEADER)
    if (loading) return <React.Fragment />
    if (error) return <p>Error</p>

    // alias header data for ease of reference in return()
    const header = data.header.data.attributes 
    
    return (
        <React.Fragment>
            <Navbar light expand="md" className="sticky-top">
                {/* Displays Logo field on left side of the header */}
                <NavbarBrand className="mx-3 mx-md-4" href="/">
                    <img src={process.env.REACT_APP_IMAGE_URL + header.Logo.data.attributes.url} alt="Bayesics" height="40"/>
                </NavbarBrand>
                
                <NavbarToggler onClick={toggleNav} />
                
                <Collapse className="justify-content-end" isOpen={toggle} navbar>
                    <Nav navbar className="mx-4">
                        {/* Maps through the Links field and displays NavLinks horizontally
                         in the header */}
                        {header.Links.map(link => (
                            <NavItem>
                                <NavLink className="nav-link" to={link.URL}>{link.Name}</NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </Collapse>
            </Navbar>
        </React.Fragment>
    )
}
