import React from 'react'

import ContactForm from './ContactForm'

export default function ContactComponent() {
  return (
    <div className="contact">
      <div className="p-5">
        <div className="d-flex row px-5 my-5 justify-content-center">
          <div className="col-12 col-md-6 text-center my-5">
            <h1>Contact Us</h1>
            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <a className="btn btn-primary mt-3" href="/contact" role="button">Get in touch</a>
          </div>
          
        </div>
      </div>
    </div>
  )
}
