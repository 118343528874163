import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap'
import { IconButton, Skeleton } from '@mui/material'
import { ArrowForwardRounded, ArrowBackRounded } from '@mui/icons-material/'
import { useQuery, gql } from '@apollo/client' 

const { REACT_APP_IMAGE_URL } = process.env

const BLOG_POSTS = gql`
    query {
      blogPosts(sort:"Date:desc") {
        data {
          id,
          attributes {
            Title,
            Subtitle,
            Content,
            Image {
              data {
                attributes {
                  url
                }
              }
            },
            Date
          }
        }
      }
    }
    
`

const responsive = {
  xxl: {
    breakpoint: { max: 3000, min: 1400 },
    items: 4,
    slidesToSlide: 4
  },
  xl: {
    breakpoint: { max: 1400, min: 1200 },
    items: 4,
    slidesToSlide: 4
  },
  lg: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  xs: {
    breakpoint: { max: 576, min: 0},
    items: 1,
    slidesToSlide: 1
  } 
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest}) => {
  const { carouselState: { currentSlide }} =  rest;
  return (
    <div className="carousel-button-group">
      <IconButton aria-label="previous" onClick={() => previous()}>
        <ArrowBackRounded />
      </IconButton>
      <IconButton aria-lable="next" onClick={() => next()}>
        <ArrowForwardRounded />
      </IconButton>
    </div>
  )
}



export default function BlogCarousel({posts}) {
  return (
    <div className="blog-carousel py-5 px-md-5">
      <div className="py-5">
        <div className="row px-5">
          <h2 className="">Recent Posts</h2>
        </div>
        <div className="row px-5 flex-row-reverse">
            {(posts.length > 0) ?
            <Carousel
              className="px-5 py-5 gx-5 d-flex align-items-stretch"
              customButtonGroup={<ButtonGroup />}
              arrows={false}
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px d-flex"
              renderButtonGroupOutside={true}
            >
              {posts.map(post => (
                <Card className>
                  {post.attributes.Image.data && 
                      <CardImg top src={REACT_APP_IMAGE_URL + post.attributes.Image.data.attributes.url} className="blog-card-img"/>
                  }
                  <CardBody>
                    {post.attributes.Image.data ? <CardTitle className="h4">{post.attributes.Title}</CardTitle> :  <CardTitle className="h2">{post.attributes.Title}</CardTitle>}
                    
                    <CardSubtitle>{post.attributes.SubTitle}</CardSubtitle>
                    <CardText>{post.attributes.Image.data ? post.attributes.Content.substring(0, 140) : post.attributes.Content.substring(0, 280)}...</CardText>
                  </CardBody>
                </Card>
              ))} 
              
            </Carousel> :
          
            // no posts
            <div className="mt-4">
            <p>There are no posts to display at the moment. Please check back later.</p>
          </div>}
        </div>
      </div>
      
    </div>
  )
}
